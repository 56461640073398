import { Form } from './Form.js'
import { Checkbox } from './Checkbox.js'
import { Checkboxes } from './Checkboxes.js'
import { Component } from './Component.js'
import { DonationAmount } from './DonationAmount.js'
import { Fieldset } from './Fieldset.js'
import { Hidden } from './Hidden.js'
import { Input } from './Input.js'
import { OptIn } from './OptIn.js'
import { Radios } from './Radios.js'
import { Recaptcha } from './Recaptcha.js'
import { Select } from './Select.js'

export const byType = {
  checkbox: Checkbox,
  checkboxes: Checkboxes,
  'donation-amount': DonationAmount,
  email: Input,
  fieldset: Fieldset,
  form: Form,
  hidden: Hidden,
  html: Component,
  input: Input,
  'opt-in': OptIn,
  password: Input,
  radio: Checkbox,
  radios: Radios,
  recaptcha: Recaptcha,
  select: Select,
  submit: Component,
  text: Input,
  textarea: Component,
}
