/**
 * Awaitable version of setTimeout.
 *
 * @param {number} ms Number of milliseconds to wait for.
 * @returns {Promise}
 */
function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export class Fetcher {
  /**
   * Create a new session fetcher instance.
   *
   * @param {string} sessionUrl
   */
  constructor(sessionUrl) {
    this.sessionUrl = sessionUrl
  }

  /**
   * Request a session (with automatic retries).
   *
   * @param {number} maxAttempts Maximimum number of attempts that will be made.
   * @returns {boolean} True on success, otherwise false.
   */
  async requestSession(maxAttempts = 10) {
    const request = new Request(this.sessionUrl, { method: 'POST' })
    let remainingAttempts = maxAttempts
    while (remainingAttempts > 0) {
      try {
        const response = await window.fetch(request)
        if (response.ok) {
          return true
        }
      }
      catch (error) {
      }
      await timeout(1000 * Math.pow(2, maxAttempts - remainingAttempts--))
    }
    return false
  }
}
