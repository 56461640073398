import * as utils from './component-utils.js'
import * as components from './components/index.js'

/**
  * Iterator for traversing the component config tree in depth-first pre-order.
  */
function * iterateComponents(componentConfig, parentComponent) {
  if (!(componentConfig.type in components.byType)) {
    console.warn('Unknown component type: ' + componentConfig.type + '.')
    // Ignore unknown components and their child components.
    return
  }
  const component = new components.byType[componentConfig.type](componentConfig, parentComponent)
  yield component
  if (componentConfig.components) {
    for (const childComponentConfig of componentConfig.components) {
      yield * iterateComponents(childComponentConfig, component)
    }
  }
}

/**
 * Class that represents a form’s component tree.
 */
export class ComponentTree {
  constructor(config) {
    this.components = Array.from(iterateComponents(config))
    this.root = this.components[0]
  }

  /**
   * Build a list of all component types used in a form-config tree.
   */
  getComponentTypesInUse() {
    return Array.from(this.root.neededTemplates())
  }
}

/**
 * Collection of available component templates.
 */
export class TemplateCollection {
  /**
   * Create a new template collection.
   */
  constructor() {
  }

  /**
   * Load templates for all the passed component types (if needed).
   */
  async loadMissingTemplates(componentTypes, templatesUrl) {
    // Fetch all mising templates asynchronously, but wait until all requests have been resolved
    return Promise.all(
      componentTypes.filter(type => !this.getElement(type)).map(type =>
        // Fetch HTML file that corresponds to each template type
        window.fetch(templatesUrl + '/' + type + '.html')
          .then(response => response.ok ? response.text() : null)
          .then(html => {
            // Add the HTML to the document body in a template tag
            if (html != null) {
              const templateElement = document.createElement('template')
              templateElement.id = utils.generateRandomId()
              templateElement.setAttribute('formtool-component', type)
              templateElement.innerHTML = html
              document.body.appendChild(templateElement)
            }
            else {
              console.error('Template "' + type + '" not loaded')
            }
          })
      )
    )
  }

  /**
   * Get template element for a given component type
   */
  getElement(componentType) {
    return document.querySelector('template[formtool-component="' + componentType + '"]')
  }
}
