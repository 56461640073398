import { Component } from './Component.js'

/**
 * Functionality for the fieldset element template.
 */
export class Fieldset extends Component {
  values() {
    let values = {}
    for (const child of this.children) {
      values = {
        ...values, ...child.values()
      }
    }
    return values
  }
}
