/**
 * Implement a @moreonion/formPrefill plugin for fetching data from an API.
 */
export class PrefillStore {
  constructor(apiKey, keys) {
    this.apiKey = apiKey
    this.keys = keys
    this._cache = null
  }

  async setItems(keys, value) {
    return true
  }

  async removeItems(keys) {
    return true
  }

  /**
   * Fetch the data from the API (cached).
   *
   * @returns object
   */
  fetchData() {
    if (!this._cache) {
      this._cache = new Promise((resolve, reject) => {
        fetch('https://pro.ip-api.com/json/?fields=countryCode&key=' + this.apiKey).then((res) => {
          return res.json()
        }).then((data) => {
          return resolve(data)
        }).catch((error) => {
          console.log(error.toString())
          reject(error)
        })
      })
    }
    return this._cache
  }

  async getFirst(keys) {
    for (const key of keys) {
      if (this.keys.includes(key)) {
        const data = await this.fetchData()
        return data.countryCode
      }
    }
    throw new Error('geoip: None of the keys was set up for prefilling')
  }
}
