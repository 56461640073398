/*
 * The following functions are designed to be imported by default
 * into the scope of the Vue app created in `buildFormFromConfig.js`,
 * in order to provide common functionality to component templates:
 */

export function generateRandomId() {
  return 'id_' + Math.random().toString().slice(2)
}

export function getValidityError(field) {
  if (field.validity) {
    if (field.validity.valid) {
      return false
    }
    for (const state in field.validity) {
      if (field.validity[state]) {
        return state
      }
    }
  }
  return null
}

/**
 * Check whether a variable is a JS object.
 * @param {*} obj
 * @returns true if obj is a JS object, otherwise false.
 */
export function isObject (obj) {
  return obj instanceof Object && !Array.isArray(obj)
}

/**
 * Returns a copy of the config with all defaults recursively merged into it.
 *
 * @param {object} config The config to merge defaults into.
 * @param {object} defaults The defaults that should be merged.
 * @returns A new config object with input config and defaults merged.
 */
export function mergeConfig (config, defaults) {
  const merged = { ...defaults }
  for (const key of Object.keys(config)) {
    if (isObject(config[key]) && key in defaults && isObject(defaults[key])) {
      merged[key] = mergeConfig(config[key], defaults[key])
    }
    else {
      merged[key] = config[key]
    }
  }
  return merged
}

/**
 * Turn a parameter with enabled flag into a simple value.
 *
 * A parameter can either be a simple value, or an object. This function evaluates those objects
 * into simple values.
 *
 * @param {object} config The config object object on which to look for the parameter.
 * @param {string} key The key of the parameter to evaluate.
 */
export function evaluateEnabledFlag(config, key) {
  if (!Object.prototype.hasOwnProperty.call(config, key) || !isObject(config[key])) {
    // The parameter doesn’t exist or is already a simple value.
    return
  }
  config[key] = mergeConfig(config[key], { enabled: true, value: null })
  config[key] = config[key].enabled ? config[key].value : null
}
