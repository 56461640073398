/**
 * Functionality to collect additional tracking data for submissions.
 */

// Create a short number we can use as user id in our tracking data storage.
function newUserId() {
  // Adler32 is a checksum algorithm.
  const adler32 = (a, b, c, d, e, f) => {
    for ((b = 65521), (c = 1), (d = e = 0); (f = a.charCodeAt(e++)); (d = (d + c) % b)) {
      c = (c + f) % b
    }
    return (d << 16) | c
  }

  return adler32(String(Math.random() + Date.now()))
}

// We want to keep the parameters but not potential prefill hashes in our urls.
function getCleanUrl() {
  let url = window.location.href
  // Check place of hashed parameters in the URL
  const hashIndex = url.indexOf('#')
  if (hashIndex !== -1) {
    // If there are hashed parameters, we want to remove them from the URL
    url = url.substring(0, hashIndex)
  }
  return url
}

// Disect URL parameters and get the ones we really want.
function getUrlParameters() {
  const url = new URL(getCleanUrl())
  const parameters = {}

  // These are the allowed tracking parameters we want to collect and map.
  const trackingParameters = [
    ['source', 's', 'utm_source'],
    ['medium', 'm', 'utm_medium'],
    ['version', 'v', 'utm_content'],
    ['term', 't', 'utm_term'],
    ['campaign', 'c', 'utm_campaign'],
    ['other', 'o'],
    ['external_referer', 'external_referrer'],
  ]

  for (const candidates of trackingParameters) {
    for (const parameter of candidates) {
      const value = url.searchParams.get(parameter)
      if (value) {
        parameters[candidates[0]] = value
      }
    }
  }

  // Are there tags in paramteres? Let's add them!
  if (url.searchParams.get('tags')) {
    parameters.tags = url.searchParams.get('tags').split(',')
  }

  return parameters
}

// We want to save tracking data into our SessionStorage.
function createOrUpdateSession() {
  // We look for our data in the session storage or create it.
  const session = JSON.parse(sessionStorage.getItem('ist_tracking')) || {}

  // If there is not yet a userid, let's create one.
  if (!session.userid) {
    session.userid = newUserId()
  }

  // If we don't already have an entry_url we want to create it.
  if (!session.entry_url) {
    session.entry_url = getCleanUrl()
    // if there are URL parameters, we will add them to the session as well.
    Object.assign(session, getUrlParameters())
  }

  // Let's save the up to date tracking data into the session.
  sessionStorage.setItem('ist_tracking', JSON.stringify(session))

  return session
}

/**
 * Create a promise that is resolved when the event is triggered for the first time.
 */
function eventHasBeenTriggered(eventName) {
  return new Promise((resolve) => {
    window.document.addEventListener(eventName, resolve, { once: true })
  })
}

export async function initTracking(trackingEvent) {
  if (trackingEvent !== undefined) {
    console.info(`Saving session data is waiting for ${trackingEvent} event.`)
    await eventHasBeenTriggered(trackingEvent)
  }
  return createOrUpdateSession()
}

// Add url & parameters to the form submission.
export function addTracking() {
  const data = {
    form_url: getCleanUrl(),
    ...JSON.parse(sessionStorage.getItem('ist_tracking')),
    ...getUrlParameters()
  }

  return data
}
