import { Component } from './Component.js'
import { addTracking } from '../tracking.js'

/**
 * Functionality for the form element template.
 */
export class Form extends Component {
  constructor(config) {
    super(config)
    this.authenticating = true
    this.errors = {}
    this.successCallback = this.meta.successCallback || (() => {
      location.assign((this.params.action && this.params.action.success) || '')
    })
    this.validationMessage = null
  }

  setValidityErrors($el, fields) {
    for (const name in fields) {
      let elements = $el.elements[name]
      if (!elements) {
        continue
      }
      if (elements instanceof HTMLElement) {
        elements = [elements]
      }
      let message = fields[name]
      if (message instanceof Array) {
        message = message[0]
      }
      for (const element of elements) {
        element.setCustomValidity(message)
        element.checkValidity()
        element.addEventListener(
          'change',
          function () {
            this.setCustomValidity('')
            this.checkValidity()
          },
          { once: true }
        )
      }
    }
  }

  values() {
    let values = {}
    for (const child of this.children) {
      values = {
        ...values, ...child.values()
      }
    }
    return values
  }

  async submit(event, $el) {
    event.preventDefault()

    const postSubmission = async (url, form, retry401 = true) => {
      if (this.errors.session && !await this.requestSession(3)) {
        return
      }
      const submissionData = {
        data: this.values(),
        tracking: addTracking()
      }
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(submissionData)
        })
        const json = await response.json()
        if (response.ok) {
          return actOnSuccess(submissionData, json)
        }
        // Submission failed due to having no valid session. Get a new session and retry.
        if (response.status === 401 && retry401) {
          this.reactive.errors.session = true
          return await postSubmission(url, form, false)
        }
        // Generic error handling, usually validation errors.
        return actOnFailure(json)
      }
      catch (error) {
        actOnFailure(null)
      }
    }

    const actOnSuccess = (formData, responseData) => {
      console.info('Valid form submission')
      this.reactive.validationMessage = null
      this.successCallback(formData, responseData)
    }

    const actOnFailure = (responseData) => {
      console.warn('Invalid form submission')
      $el.scrollIntoView({ behavior: 'smooth' })
      if (responseData) {
        if (responseData.fields) {
          this.setValidityErrors($el, responseData.fields)
        }
        else {
          this.reactive.validationMessage = responseData.detail || 'Server error; form not submitted'
        }
      }
      $el.dispatchEvent(new Event('submitted:invalid'))
    }

    const recaptchaField = $el.elements['g-recaptcha-response']
    const submissionURL = this.meta.submission_url

    if (!$el.checkValidity()) {
      actOnFailure()
    }
    else if (submissionURL == null) {
      actOnSuccess()
    }
    else if (!recaptchaField || recaptchaField.value) {
      postSubmission(submissionURL, $el)
    }
    else if (window.grecaptcha && window.grecaptcha.execute) {
      window.grecaptcha.execute()
    }
  }

  async requestSession (maxAttempts) {
    this.reactive.authenticating = true
    const timeoutId = window.setTimeout(() => {
      this.reactive.validationMessage = this.params.errorMessages?.authenticatingSession || 'Connecting to backend service …'
    }, 5000)
    const success = await this.sessionFetcher.requestSession(maxAttempts)
    window.clearTimeout(timeoutId)
    if (!success) {
      this.reactive.validationMessage = this.params.errorMessages?.noSession || 'Could not connect to the backend service.'
    }
    this.reactive.authenticating = false
    return success
  }
}
