import { evaluateEnabledFlag, mergeConfig } from '../component-utils.js'

import { Component } from './Component.js'

const PROMISE_NAME = 'recaptchaScriptLoaded'
const CALLBACK_NAME = 'recaptchaOnloadCallback'

/**
 * Functionality for the recaptcha form component.
 */
export class Recaptcha extends Component {
  constructor (config, parentComponent) {
    super(config, parentComponent)
    evaluateEnabledFlag(this.params, 'terms')
  }

  mounted ($el) {
    super.mounted($el)
    const badgeWrapper = $el.children[0]
    if (this.params.terms) {
      badgeWrapper.style.display = 'none'
    }
    if (!window[PROMISE_NAME]) {
      window[PROMISE_NAME] = new Promise((resolve, reject) => {
        window[CALLBACK_NAME] = resolve
        const recaptchaScript = document.createElement('script')
        recaptchaScript.async = true
        recaptchaScript.defer = true
        recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=' + CALLBACK_NAME
        document.head.appendChild(recaptchaScript)
      })
    }
    window[PROMISE_NAME].then(() => {
      window.grecaptcha.render(badgeWrapper, {
        size: 'invisible',
        badge: 'inline',
        sitekey: this.params.sitekey,
        callback: (token) => {
          const form = $el.closest('form')
          if (form.requestSubmit) {
            form.requestSubmit()
          }
          else {
            form.dispatchEvent(new Event('submit'))
          }
        }
      })
    })
    $el.closest('form').addEventListener('submitted:invalid', () => {
      window.grecaptcha.reset($el)
    })
  }

  values() {
    const element = this.$el.querySelector('.g-recaptcha-response')
    return { [this.params.name]: element ? element.value : null }
  }
}
