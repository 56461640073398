import { Component } from './Component.js'

export class Radios extends Component {
  constructor(config, parentComponent) {
    super(config, parentComponent)
    this.value = config.params.value ?? null
  }

  values() {
    return { [this.params.name]: this.value }
  }
}
