import { evaluateEnabledFlag } from '../component-utils.js'

import { Component } from './Component.js'

/**
 * (Base)Class for input HTML elements.
 */
export class Input extends Component {
  constructor(config, parentComponent) {
    const flagAttributes = [
      'description', 'min', 'minlength', 'max', 'maxlength', 'pattern', 'placeholder', 'step'
    ]
    super(config, parentComponent)
    for (const attr of flagAttributes) {
      evaluateEnabledFlag(this.params, attr)
    }
    this.validating = true
  }

  attributesFromParams($el, exclude = []) {
    const validationAttributes = ['min', 'minlength', 'max', 'maxlength', 'pattern', 'required', 'step']
    if (!this.validating) {
      exclude = [...exclude, ...validationAttributes]
      for (const attribute of validationAttributes) {
        if (attribute in $el) {
          $el.removeAttribute(attribute)
        }
      }
    }
    super.attributesFromParams($el, exclude)
  }

  /**
   * Always use the input.html template.
   */
  templateName() {
    return 'input'
  }
}
